<script setup lang="ts">

</script>

<template>
  <div class="modal_container">
    <div class="popup_container">
      <Background :background="'left'" :darken-top="false" />
      <slot />
    </div>
  </div>
</template>

<style scoped>
@import "assets/styles/pages.css";
</style>